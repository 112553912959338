import Vue from 'vue'
import Main from './main.vue'
import vuetify from '@/plugins/vuetify'
const MessageConstructor = Vue.extend(Main)
function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key)
}
function isVNode(node) {
  return node !== null && typeof node === 'object' && hasOwn(node, 'componentOptions')
}
let instance
const instances = []
let seed = 1

const Message = function (options) {
  if (Vue.prototype.$isServer) return
  options = options || {}
  if (typeof options === 'string') {
    options = {
      message: options,
    }
  }
  const id = 'message_' + seed++

  instance = new MessageConstructor({
    vuetify,
    data: options,
  })
  instance.id = id
  if (isVNode(instance.message)) {
    instance.$slots.default = [instance.message]
    instance.message = null
  }
  instance.$mount()
  document.body.appendChild(instance.$el)
  let verticalOffset = options.offset || 20
  instances.forEach(item => {
    verticalOffset += item.$el.offsetHeight + 16
  })
  instance.verticalOffset = verticalOffset
  instance.visible = true
  instances.push(instance)
  return instance
}
export default Message

Vue.prototype.$message = Message
