<!--  -->
<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ title || 'title' }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('cancel', $event)"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0" :style="{ height: height }">
        <slot></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" @click="$emit('ok', $event)" dark>提交</v-btn>
        <v-btn color="blue darken-1" text @click="$emit('cancel', $event)">关闭</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModelDialog',
  props: { title: String, height: [String] },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
