<template>
  <a-config-provider :locale="zhCN">
    <v-app>
      <router-view />
    </v-app>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'

import { AppDeviceEnquire } from '@/utils/mixin'

export default {
  mixins: [AppDeviceEnquire],
  data() {
    return {
      zhCN,
    }
  },
  mounted() {},
}
</script>
<style>
#app {
  height: 100%;
}
</style>
