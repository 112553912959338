<!--  -->
<template>
  <v-app>
    <v-overlay :value="visible">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
}
</script>

<style lang="scss" scoped></style>
