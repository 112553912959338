// ie polyfill
import '@babel/polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
// import { VueAxios } from './utils/request'
import AxiosPlugin from '@/utils/plugin/axios-plugin'

// mock
// import './mock'
import './global'
import bootstrap from './core/bootstrap'
import './core/use'
import './permission' // permission control
import operatorPlugin from './utils/plugin/operator-plugin' // 权限管理
import vuetify from './plugins/vuetify'
import 'xe-utils'
import VXETable from 'vxe-table'
import './plugins/vxeTable'
import 'vxe-table/lib/style.css'

Vue.use(VXETable)
if (process.env.NODE_ENV == 'development') {
  Vue.config.devtools = true
  Vue.config.productionTip = true

  Vue.mixin({
    methods: {
      logArgs(...args) {
        console.log('logArgs:', args)
      },
    },
  })
} else {
  Vue.config.devtools = false
  Vue.config.productionTip = false
}

// mount axios Vue.$http and this.$http
// Vue.use(VueAxios)
Vue.use(AxiosPlugin)
Vue.use(operatorPlugin)

window.app = new Vue({
  router,
  store,
  created: bootstrap,
  vuetify,
  render: h => h(App),
}).$mount('#app')
