import Vue from 'vue'
import './snackbar'
import './loading'
import * as components from './components'
if (components) {
  for (const key in components) {
    const component = components[key]
    if (component) {
      console.log(key)
      Vue.component(key, component)
    }
  }
}
