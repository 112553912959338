import Vue from 'vue'
import $$ from './utils/$$'
import '@/assets/scss/init.scss'
// import '@linusborg/vue-simple-portal'
/* ------------------------EventBus------------------------------ */
var EventBus = new Vue()

Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus
    },
  },
})

/* ------------------------API------------------------------ */
import moment from 'moment'
moment.prototype.toJSON = function () {
  return moment(this).format('YYYY-MM-DD HH:mm:ss')
}

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn') // 全局使用简体中文
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore)
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)
/* ------------------------Vue Global Variable------------------------------ */
Object.defineProperties(Vue.prototype, {
  $$: {
    get() {
      return $$
    },
  },
  $dayjs: {
    get() {
      return dayjs
    },
  },
  $moment: {
    get() {
      return moment
    },
  },
  $runAsync: {
    get() {
      return $$.runAsync
    },
  },
})

/* ------------------------公共filter ------------------------------ */
import Filters from './utils/filter.js'
for (const key in Filters) {
  Vue.filter(key, Filters[key])
}

/* ------------------------公共directive ------------------------------ */
import Directives from './directives/index.js'
Vue.use(Directives) //全局使用directive文件;

/* ------------------------Vue Global Components---全局组件------------------------------ */
const files = require.context('./components/Base', true, /\.vue$/)
files.keys().forEach(key => {
  const componentName = key.replace(/^\.\//, '').replace(/\.\w+$/, '')
  const componentConfig = files(key)
  Vue.component(componentName, componentConfig.default || componentConfig)
})
import './plugins/vuetifyCover'
