// 传入错误后的默认图片
import Vue from 'vue'
const imageIsExist = function (url) {
  return new Promise(resolve => {
    if (!url) {
      //当本身没有url的时候，我其实就想要后备图片
      resolve(false)
      return
    }
    var img = new Image()
    img.onload = function () {
      if (this.complete == true) {
        resolve(true)
        img = null
      }
    }
    img.onerror = function () {
      resolve(false)
      img = null
    }
    img.src = url
  })
}

export default {
  errImg(el, binding) {
    const imgURL = binding.value || Vue.prototype.$rootUrl + '/assets/images/master-logo.png' //获取图片地址
    const realURL = el.src
    imageIsExist(realURL).then(function (exist) {
      if (!exist) {
        el.setAttribute('src', imgURL)
      }
    })
  },
}
