import axios from 'axios'
import TokenCache from '@/utils/cache/TokenCache'
import defaultSettings from '@/config/defaultSettings'
import ProcessHelper from '@/utils/helper/ProcessHelper'
import ShowError from './ShowError'

const rootUrl = () => {
  // return defaultSettings.rootUrl
  if (ProcessHelper.isProduction() || ProcessHelper.isPreview()) {
    return defaultSettings.publishRootUrl
  } else {
    return defaultSettings.localRootUrl
  }
}
const config = {
  baseURL: rootUrl(),
  timeout: defaultSettings.apiTimeout,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
}
function handleError(error) {
  if (error.message?.includes('timeout')) {
    error.message = '请求超时！'
  }

  // 利用event loop ，等catch处理完后再进入预设处理，当调用hideNormalError时候，就不进行默认处理
  // ex :this.$ajax('/abp/servies/get..').catch((err,hide)=>{hide()})
  let isShowNormalError = true
  const hideNormalError = () => (isShowNormalError = false)
  setTimeout(() => {
    if (isShowNormalError) {
      new ShowError(error)
    }
  })
  return Promise.reject({ err: error, hide: hideNormalError }) // 在error.response上添加方法
}

export const Axios = axios.create(config)
export const _ajax = axios.create(config)

// 在发送请求之前做某件事
Axios.interceptors.request.use(
  config => {
    // 设置以 form 表单的形式提交参数，如果以 JSON 的形式提交表单，可忽略
    // if (config.method === 'post') {
    //     // JSON 转换为 FormData
    //     const formData = new FormData()
    //     Object.keys(config.data).forEach(key => formData.append(key, config.data[key]))
    //     config.data = formData
    // }

    // 携带token
    if (TokenCache.getToken()) {
      config.headers.Authorization = 'Bearer ' + TokenCache.getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(res => {
  // 授权失败
  if (!res.data.Success && res.data.ErrorCode == 401) {
    TokenCache.deleteToken()
    location.href = '/'
  }
  if (!res.data.Success) {
    return handleError(res.data)
  }
  return res.data
}, handleError)

// 在发送请求之前做某件事
_ajax.interceptors.request.use(
  config => {
    // 携带token
    if (TokenCache.getToken()) {
      config.headers.Authorization = 'Bearer ' + TokenCache.getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 返回状态判断(添加响应拦截器)
_ajax.interceptors.response.use(res => {
  // 授权失败
  if (!res.data.Success && res.data.ErrorCode == 401) {
    TokenCache.deleteToken()
    location.href = '/'
  }
  if (!res.data.Success) {
    return handleError(res.data)
  }
  return res.data.Data
}, handleError)

export default {
  install(Vue) {
    Object.defineProperty(Vue.prototype, '$ajax', { value: _ajax })
    Object.defineProperty(Vue.prototype, '$http', { value: Axios })
    Object.defineProperty(Vue.prototype, '$rootUrl', { value: rootUrl() })
  },
}
