<!--  -->
<template>
  <v-app>
    <v-snackbar
      v-model="visible"
      :bottom="bottom"
      :color="color"
      :left="left"
      :multi-line="multiLine"
      :right="right"
      :timeout="timeout"
      :top="top"
    >
      <slot>
        <p v-html="message"></p>
      </slot>

      <template v-slot:action="{ attrs }">
        <v-btn v-if="showClose" dark text v-bind="attrs" @click="visible = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: '',
      timeout: 4000,
      showClose: true,
      color: '',
      top: false,
      bottom: false,
      left: false,
      right: false,
      multiLine: false,
    }
  },
}
</script>

<style lang="scss" scoped></style>
