import Vue from 'vue'
//true是PC端，false是手机端
const isPC = (function () {
  var userAgentInfo = navigator.userAgent
  var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
})()
const isProduction = process.env.NODE_ENV === 'production'

class UtilClass {
  //复制到剪贴板
  clipCopy(content, target) {
    var aux = document.createElement('input')
    aux.setAttribute('value', content)
    console.log(content)
    // aux.style.display = "none";
    var elm = target.parentNode || document.body
    elm.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    elm.removeChild(aux)
  }
  //判断类型
  typeof(obj) {
    var class2type = {}
    // 生成class2type映射
    'Boolean Number String Function Array Date RegExp Object Error'.split(' ').map(function (item) {
      class2type['[object ' + item + ']'] = item.toLowerCase()
    })
    if (obj == null) {
      return obj + ''
    }
    return typeof obj === 'object' || typeof obj === 'function'
      ? class2type[Object.prototype.toString.call(obj)] || 'object'
      : typeof obj
  }
  getType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1)
  }
  //防抖函数
  //(延迟执行的函数,延迟时间)
  debounce(method, wait = 300, immediate = false) {
    let timeout, result
    const debounced = function (...args) {
      var isPromise = a => a instanceof Promise
      // 返回一个Promise，以便可以使用then或者Async/Await语法拿到原函数返回值
      return new Promise(resolve => {
        const context = this
        if (timeout) {
          clearTimeout(timeout)
        }
        if (immediate) {
          const callNow = !timeout
          timeout = setTimeout(() => {
            timeout = null
          }, wait)
          if (callNow) {
            result = method.apply(context, args)
            // 将原函数的返回值传给resolve
            if (isPromise(result)) {
              result.then(res => resolve(res))
            } else {
              resolve(result)
            }
          }
        } else {
          timeout = setTimeout(() => {
            result = method.apply(context, args)
            // 将原函数的返回值传给resolve
            if (isPromise(result)) {
              result.then(res => resolve(res))
            } else {
              resolve(result)
            }
          }, wait)
        }
      })
    }

    debounced.cancel = function () {
      clearTimeout(timeout)
      timeout = null
    }

    return debounced
  }
  //获取queryString
  getUrlParam(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    var r = window.location.search.substr(1).match(reg)
    if (r !== null) return decodeURIComponent(r[2])
    return null
  }
  //取个uuid
  newid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
  }
  runAsync(p, options = {}) {
    return new Promise((resolve, reject) => {
      options = Object.assign(
        {
          fullscreen: true,
          lock: true,
          text: '请求中',
          // background: 'rgba(0, 0, 0, 0.7)'
        },
        options
      )
      const loading = Vue.prototype.$loading(options)
      try {
        p.then(res => {
          resolve(res)
          loading.close()
        }).catch(err => {
          reject(err)
          loading.close()
        })
        // .finally(() => loading.close());//fixbug 有微任务问题
      } catch (err) {
        reject(err)
        loading.close()
      }
    })
  }
  noop = function () {}
  isProduction = isProduction
  isPC = isPC
  // isIframe = window !== top
  query = document.querySelector.bind(document)
  queryAll = document.querySelectorAll.bind(document)
  fromId = document.getElementById.bind(document)
  fromClass = document.getElementsByClassName.bind(document)
  fromTag = document.getElementsByTagName.bind(document)
  jsonCopy = d => JSON.parse(JSON.stringify(d))
  customerServicePhone = '15988939422，15906863922'
}
const $$ = new UtilClass()
// window.$$ = $$;
export default $$
