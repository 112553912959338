export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

/**
 * 传入callback，在往上滚和往下滚时候，触发回调传入up,down
 */
export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

/**
 * Fixed数字，如果是都是0就不要丢，否则根据第一个参数进行省略
 * @param {number|string} num 需要被格式化的数据
 * @param {number} fractionDigits 保留几位，默认两位
 * @param {boolean} showZero 为空时展示0还是空字符串 默认0
 * @return {number} 格式化数字
 */
export function toFixed(num, fractionDigits = 2, showZero = true) {
  num = Number(num)
  if (isNaN(num) || !num) return showZero ? '0' : ''
  return Number.isInteger(num) ? String(num) : num.toFixed(fractionDigits)
}

/**
 *滚动到最右侧
 * @param {Element} ele 需要滚动的Dom对象
 */
export function scrolToRight(ele) {
  const sTop = ele.scrollTop
  const sLeft = ele.scrollLeft
  const d = ele.scrollWidth - ele.clientWidth - sLeft
  if (d > 0) {
    window.requestAnimationFrame(() => {
      scrolToRight(ele)
    })
    ele.scrollTo(sLeft + (d * 0.1 > 5 ? d * 0.2 : 5), sTop)
  }
}
