<template>
  <date-picker
    :value="calcValue"
    @change="handleChange"
    v-bind="$attrs"
    v-on="$listeners"
    :format="format"
  ></date-picker>
</template>

<script>
import { DatePicker } from 'ant-design-vue'
import moment from 'moment'

export default {
  name: 'IDatePicker',
  components: {
    DatePicker,
  },
  props: {
    value: String,
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
  },
  computed: {
    calcValue() {
      return this.value ? moment(this.value) : null
    },
  },
  methods: {
    handleChange(val) {
      const value = val.format(this.format)
      this.$emit('input', value)
    },
  },
}
</script>
