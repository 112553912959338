import Vue from 'vue'
class ShowError {
  constructor(error) {
    this.error = error
    this.showError(error)
    // if (!error.response.data) {
    //   this.handleDefault();
    // } else if (!error.response) {
    //   //应该不会出现response没有的情况，这个axios返回的数据
    //   this.showError(this.defaultError);
    // } else {
    //   this.handleResponseReject(error.response);
    // }
  }
  basePath = '/'
  defaultError = {
    message: '请求时出错!',
    details: '请求服务器发生错误，未登录、注册或缺少权限都可能导致，请重试或检查并联系管理员！',
  }

  defaultError401 = {
    message: '您的身份验证未通过!',
    details: '需登录用户才能执行此操作，请检查是否登录或注册模来模往！',
  }

  defaultError403 = {
    message: '您的权限验证未通过!',
    details: '您暂无执行此操作的权限，请联系管理员添加权限。',
  }

  defaultError404 = {
    message: '404-找不到资源!',
    details: '在服务器上找不到请求的资源。',
  }

  handleDefault() {
    switch (this.error.response.status) {
      case 401:
        this.handleUnAuthorizedRequest(this.showError(this.defaultError401), this.basePath)
        break
      case 403:
        this.showError(this.defaultError403)
        break
      case 404:
        this.showError(this.defaultError404)
        break
      default:
        this.showError(this.defaultError)
        break
    }
  }
  handleResponseReject(response) {
    const { data, status } = response
    const p = this.showError(data.error || this.defaultError)

    // 未登录状态报401需要跳转
    if (status === 401) {
      this.handleUnAuthorizedRequest(p, data.targetUrl || this.basePath)
    }
  }
  showError(errObj) {
    // let page = router.currentRoute.meta ? router.currentRoute.meta.title || "" : "";
    // page = page ? `【${page}】` : "";
    // // let url = "<br>" + this.error.config.url;
    // const title = errObj.message || this.defaultError.message;
    // const content =
    //   errObj.details || (errObj.message ? `请尝试刷新${page}或与管理员联系！` : this.defaultError.details);
    // + url;
    return Vue.prototype.$error({ content: errObj.Msg, title: '错误' })
    // return Vue.prototype.$alert(content, title, {
    //   dangerouslyUseHTMLString: true,
    //   type: "warning",
    // });
    // return Vue.prototype.$notify({
    //   type: "warning",
    //   title: title,
    //   message: content,
    //   dangerouslyUseHTMLString: true,
    // });
  }

  handleTargetUrl(targetUrl) {
    location.href = targetUrl
  }
  // 进行页面跳转
  handleUnAuthorizedRequest(messagePromise, targetUrl = this.basePath) {
    if (messagePromise instanceof Promise) {
      messagePromise.then(() => this.handleTargetUrl(targetUrl))
    } else {
      this.handleTargetUrl(targetUrl)
    }
  }
}
export default ShowError
