import Vue from 'vue'
import Main from './main.vue'
import vuetify from '@/plugins/vuetify'
const LoadingConstructor = Vue.extend(Main)
let instance
let seed = 1

const Loading = function () {
  if (instance) return instance
  if (Vue.prototype.$isServer) return
  const id = 'message_' + seed++

  instance = new LoadingConstructor({
    vuetify,
    data: {
      visible: true,
    },
  })
  instance.id = id
  instance.$mount()
  document.body.appendChild(instance.$el)
  instance.close = function () {
    instance.visible = false
    instance = null
  }

  return instance
}
Loading.close = function () {
  instance.visible = false
  instance = null
}
export default Loading

Vue.prototype.$loading = Loading
